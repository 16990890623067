<template>
	<h1>Form Implementation</h1>
	<div class="text-block">
		<h3>General</h3>
		<ul>
			<li>For each form item, set <span class="code">v-model</span> to data attribute.</li>
			<li>
				Most form items support a <span class="code">label</span> parameter which will render it with a label.
			</li>
		</ul>

		<h3>Error Handling</h3>
		<ul>
			<li>
				Form items should be wrapped inside a form element with
				<span class="code">novalidate</span> attribute.
			</li>
			<li>
				Set up data error object with null value per field plus general error.
			</li>
			<li>Set up the <span class="code">resetError()</span> function.</li>
			<li>
				Incluce general error message with class
				<span class="code">error-msg small</span> at bottom of the form.
			</li>
			<li>
				Most form items support an <span class="code">error</span> parameter displayed next to the label text.
			</li>
			<li>
				Error messages are usually handled in the backend via data model validation.
			</li>
		</ul>

		<h3>Layout</h3>
		<ul>
			<li>Form items are <span class="code">2.5rem (250px)</span> by default</li>
			<li>
				A custom width can be passed like this:
				<span class="code">&#60;FormText :width="300" /&#62;</span>
			</li>
			<li>
				For forms with multiple items, wrap the form items in an element with class
				<span class="code">structured-form</span>. This will automatically create a flexbox layout with0.3rem
				vertical gap,0.4rem horizontal gap,0.1rem button gap, making for consistently structured forms. If the
				form should use two columns, add the extra class <span class="code">two-cols</span>.
			</li>
			<li>
				you can set custom margins for any form item by either passing
				<span class="code">:mT</span>/<span class="code">:mR</span>/<span class="code">:mB</span>/<span
					class="code"
					>:mL</span
				>, or <span class="code">:margin="[10, 20, 5, 10]"</span> to set all margins at once.
			</li>
		</ul>
		<br />

		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import FormText from '@/components/FormText'
import FormDropdown from '@/components/FormDropdown'

export default {
	components: { FormText, FormDropdown },
	data() {
		return {
			name: 'Arthur',			// v-model for text input
			occupation: 'Arthur',	// v-model for dropdown
			errors: {				// Error object to manage error states
				name: null,
				occupation: null
				general: null,
			},
		}
	},
	methods: {
		resetError(fieldName) {
			if (fieldName) this.errors[fieldName] = ''
			this.errors.general = ''
		},
	},
}</code></pre>
	</div>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsImplementation',
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Implementation',
		})
	},
	data() {
		return {
			html: [
				'<form novalidate class="structured-form">',
				'	<FormText v-model="name" :error="errors.name" ... />',
				'	<FormDropdown  v-model="occupation" :error="errors.occupation" ... />',
				'	<p class="error-msg small">{{ errors.general }}</p>',
				'</form>',
			].join('\n'),
		}
	},
}
</script>

<style scoped lang="scss"></style>
